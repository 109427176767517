import React from "react";
import { Card, Row, Col } from "antd";
import NameAndTaglineImage from "../../assets/name-and-tagline.png"
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Feature1Image from "../../assets/feature1.jpg";
import Feature2Image from "../../assets/feature2.jpg";
import Feature3Image from "../../assets/feature3.jpg";

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default ({ children }) => {
  const slides = [
    {
      url: Feature1Image,
      width: "100%",
      title: "Access data on your fingertips",
      desc: "View & share invoices, outstanding, mill, job and other reports on your mobile"
    },
    {
      url: Feature2Image,
      width: "90%",
      title: "Digitize your order book",
      desc: "Manage orders, design catalogues and pending orders on your phone"
    },
    {
      url: Feature3Image,
      width: "80%",
      title: "Industry best security",
      desc: "OTP based login, admin approval, IP whitelisting, SSL encryption & much more"
    }
  ]

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#ebf9ff",
      }}
    >
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
          xxl={{ span: 12, offset: 6 }}
        >
          <Card style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)", padding: 0, border: "none" }} styles={{ body: { padding: 0 } }}>
            <Row>
              <Col sm={12}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                  padding: 48,
                }}>
                  <img src={NameAndTaglineImage} alt="Simple Hisaab" style={{ width: "100%" }} />
                  <div style={{ flex: 1 }}></div>
                  <div>
                    {children}
                  </div>
                  <div style={{ flex: 2 }}></div>
                </div>
              </Col>
              <Col sm={12}>
                <div style={{
                  display: "flex", flexDirection: "column",
                  borderLeft: "1px solid #ccc",
                  height: "100%"
                }}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: 48,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}>
                    <div style={{ width: "100%" }}>
                      <Slide
                        indicators={true}
                        autoplay={false}
                        duration={3000}
                        canSwipe={true}
                        arrows={false}
                        defaultIndex={randomIntFromInterval(0, 2)}
                      >
                        {slides.map((slide, index) => (
                          <>
                            <div key={index} style={{ width: "100%", height: "240px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img src={slide.url} alt="" style={{ objectFit: "cover", width: slide.width }} />
                            </div>
                            <div style={{ textAlign: "center", fontSize: 16, color: "rgba(0,0,0,0.87)", fontWeight: "bold", marginTop: 16 }}>
                              {slide.title}
                            </div>
                            <div style={{ textAlign: "center", fontSize: 16, color: "rgba(0,0,0,0.54)", marginTop: 8, fontWeight: "lighter" }}>
                              {slide.desc}
                            </div>
                          </>
                        ))}
                      </Slide>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <div style={{ marginTop: 32, display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "bold", justifyContent: "center" }}>
            <span className="material-icons-outlined" style={{ fontSize: 22, width: 24, color: "rgba(0,0,0,0.54)" }}>contact_support_outlined</span>
            <span style={{ color: "rgba(0,0,0,0.54)", marginLeft: 4 }}>Technical Support: </span>
            <span style={{ marginLeft: 8, color: "#0092e0" }}>91061-33533, 63630-30989, 77108-77175</span>
          </div>
          <div style={{ marginTop: 8, display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "bold", justifyContent: "center" }}>
            <span className="material-icons-outlined" style={{ fontSize: 24, width: 24, color: "rgba(0,0,0,0.54)" }}>schedule_outlined</span>
            <span style={{ color: "rgba(0,0,0,0.54)", marginLeft: 4 }}>Support Timing:</span>
            <span style={{ marginLeft: 8, color: "#0092e0" }}>11.00 A.M. To 7.00 P.M. (Monday to Saturday)</span>
          </div>
          <div style={{ marginTop: 8, display: "flex", alignItems: "center", fontSize: "18px", fontWeight: "bold", justifyContent: "center" }}>
            <span className="material-icons-outlined" style={{ fontSize: 24, width: 24, color: "rgba(0,0,0,0.54)" }}>request_quote_outlined</span>
            <span style={{ color: "rgba(0,0,0,0.54)", marginLeft: 4 }}>Sales / Demo Inquiry:</span>
            <span style={{ marginLeft: 8, color: "#0092e0" }}>98984-15532, 98245-06821</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
