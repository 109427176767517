import React from "react";
import { Input } from "antd";
import FloatLabel from "../float-label/Index";

const CustomTextArea = ({
  uppercase = true,
  value,
  onChange,
  onKeyDown,
  onNext,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  required,
  forwardedRef,
  className,
  onFocus,
  ...restProps
}) => {
  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  const ref = forwardedRef || null;

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (onNext) {
        onNext(event);
      }
    }
  };

  return (
    <FloatLabel label={placeholder} required={required}>
      <Input.TextArea
        className={`custom-input ant-input-sm ${readOnly ? "read-only" : ""} ${className}`}
        value={value}
        onChange={readOnly ? null : onChange}
        ref={ref}
        onInput={(e) => {
          if (uppercase) {
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;
            e.target.value = e.target.value.toUpperCase();
            e.target.setSelectionRange(start, end);
          }
        }}
        onFocus={(e) => {
          e.target.select();
          onFocus?.();
        }}
        readOnly={readOnly}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        {...restProps}
      />
    </FloatLabel>
  );
};

export default CustomTextArea;
