import { del, get, set } from "automate-redux";
import { nanoid } from "nanoid";
import { axios } from "../client";
import { notifyError } from "../utils";
import packageInfo from "../../package.json";

export const getSessions = () => {
  const sessions = JSON.parse(localStorage.getItem("sessions") || "[]");
  const currentTimeStr = new Date().toISOString();
  const unexpiredSessions = sessions.filter((session) => session.expiresAt > currentTimeStr);
  return unexpiredSessions;
};

const setSelectedSessionId = (sessionId) => (dispatch) => {
  const prevSessionId = localStorage.getItem("selectedSessionId");
  dispatch(set("selectedSessionId", sessionId));
  localStorage.setItem("selectedSessionId", sessionId);
  if (sessionId && sessionId !== prevSessionId) {
    dispatch(changeFcYear(null, null));
    dispatch(set("profileLoaded", false))
  }
};

const removeSelectedSessionId = () => (dispatch) => {
  dispatch(del("selectedSessionId"));
  localStorage.removeItem("selectedSessionId");
};

const setSessions = (sessions) => localStorage.setItem("sessions", JSON.stringify(sessions));

export const performActionsOnSessionAdded = (session) => async (dispatch) => {
  const sessions = getSessions();
  sessions.push(session);
  setSessions(sessions);
  dispatch(setSelectedSessionId(session.id));
  dispatch(set("screenLockPassed", true));
};

export const performActionsOnSessionExpired = (sessionId) => (dispatch) => {
  const sessions = getSessions();
  const filteredSessions = sessions.filter((obj) => obj.id !== sessionId);
  setSessions(filteredSessions);
  dispatch(set("screenLockPassed", false));
  dispatch(removeSelectedSessionId());
  dispatch(changeFcYear(null, null));
};

export const getLoginOtp = async (email) => {
  const { status, data } = await axios.post("/v1/getLoginOtp", { email });
  if (status !== 200) {
    throw data;
  }

  return data.reqId;
};

export const verifyLoginOtp = (reqId, otp) => async (dispatch) => {
  const { status, data } = await axios.post("/v1/verifyLoginOtp", { reqId, otp });
  if (status !== 200) {
    throw data;
  }

  dispatch(performActionsOnSessionAdded(data));
};

export const registerAccount = async (accountDetails) => {
  const { status, data } = await axios.post("/v1/registerAccount", accountDetails);
  if (status !== 200) {
    throw data;
  }

  return data.reqId;
};

export const verifyAccount = (reqId, otp) => async (dispatch) => {
  const { status, data } = await axios.post("/v1/verifyAccount", { reqId, otp });
  if (status !== 200) {
    throw data;
  }

  dispatch(performActionsOnSessionAdded(data));
};

export const getResetPinOtp = async (sessionId) => {
  const { status, data } = await axios.post("/v1/getResetPinOtp", { sessionId });
  if (status !== 200) {
    throw data;
  }

  return data.reqId;
};

export const resetPin = async (sessionId, reqId, otp, pin) => {
  const { status, data } = await axios.post("/v1/resetPin", { sessionId, reqId, otp, pin });
  if (status !== 200) {
    throw data;
  }
};

export const changePin = async (oldPin, newPin) => {
  const { status, data } = await axios.post("/v1/changePin", { oldPin, newPin });
  if (status !== 200) {
    throw data;
  }
};

export const verifyPin = (sessionId, pin) => async (dispatch) => {
  const { status, data } = await axios.post("/v1/verifyPin", { sessionId, pin });
  if (status !== 200) {
    throw data;
  }

  const sessions = getSessions();
  const updatedSessions = sessions.map((obj) =>
    obj.id === sessionId ? { ...obj, expiresAt: data.expiresAt } : obj
  );
  setSessions(updatedSessions);
  dispatch(setSelectedSessionId(sessionId));
  dispatch(set("screenLockPassed", true));
};

export const getOrgAccessOtp = async (orgId, period) => {
  const { status, data } = await axios.post("/v1/getOrgAccessOtp", { org_id: orgId, period });
  if (status !== 200) {
    throw data;
  }

  return data.reqId;
};

export const verifyOrgAccessOtp = (reqId, otp) => async (dispatch, getState) => {
  const { status, data } = await axios.post("/v1/verifyOrgAccessOtp", { reqId, otp });
  if (status !== 200) {
    throw data;
  }

  const { orgId, expiresAt } = data;
  const orgs = getOrganizations(getState());
  const updatedOrgs = orgs.map((obj) =>
    obj.id === orgId ? { ...obj, expires_at: expiresAt } : obj
  );
  dispatch(set("orgs", updatedOrgs));
};

export const fetchActiveSessions = async () => {
  const { status, data } = await axios.get("/v1/getActiveSessions");
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const terminateSession = async (sessionId) => {
  const { status, data } = await axios.post("/v1/terminateSession", { sessionId });
  if (status !== 200) {
    throw data;
  }
};

export const logout = () => async (dispatch) => {
  const { status, data } = await axios.post("/v1/logout");
  if (status !== 200) {
    throw data;
  }

  dispatch(performActionsOnSessionExpired(localStorage.getItem("selectedSessionId")));
};

export const createOrganization = (orgDetails) => async (dispatch, getState) => {
  const orgId = nanoid(10);
  const orgObj = { id: orgId, ...orgDetails };
  const { status, data } = await axios.post("/v1/createOrganization", orgObj);
  if (status !== 200) {
    throw data;
  }

  await dispatch(loadUserOrganizations());

  const orgs = getOrganizations(getState());
  const org = orgs.find((obj) => obj.id === orgId);
  const fcYearId = org.fc_years[0].id;
  dispatch(changeFcYear(orgId, fcYearId));
};

export const getCustomerCreateOtp = async (email, details) => {
  const { status, data } = await axios.post("/v1/getCustomerCreateOtp", { email, details });
  if (status !== 200) {
    throw data;
  }

  return data.reqId;
};

export const createCustomer = async (reqId, otp, details) => {
  const reqBody = { reqId, otp, details };
  const { status, data } = await axios.post("/v1/admin-portal/createCustomer", reqBody);
  if (status !== 200) {
    throw data;
  }
};

export const createOrgGroup = (orgGroupDetails) => async (dispatch, getState) => {
  const state = getState();
  const selectedOrgId = getSelectedOrgId(state);
  const body = { org_id: selectedOrgId, data: orgGroupDetails };
  const { status, data } = await axios.post("/v1/createOrgGroup", body);
  if (status !== 200) {
    throw data;
  }

  await dispatch(loadUserOrganizations());
};

export const updateOrgGroup = (ogmId, orgGroupDetails) => async (dispatch, getState) => {
  const body = { id: ogmId, data: orgGroupDetails };
  const { status, data } = await axios.post("/v1/updateOrgGroup", body);
  if (status !== 200) {
    throw data;
  }

  await dispatch(loadUserOrganizations());
};

export const addUserToCompany = (email, details) => async (dispatch, getState) => {
  const state = getState();
  const selectedOrgId = getSelectedOrgId(state);
  const body = { email, org_id: selectedOrgId, ...details };
  const { status, data } = await axios.post("/v1/addUser", body);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(state);
  const newOrgs = orgs.map((obj) => {
    if (obj.id === selectedOrgId) {
      return { ...obj, users: [...obj.users, data] };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgs));
};

export const removeUserFromCompany = (userId) => async (dispatch, getState) => {
  const state = getState();
  const selectedOrgId = getSelectedOrgId(state);
  const body = { user_id: userId, org_id: selectedOrgId };
  const { status, data } = await axios.post("/v1/removeUser", body);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(getState());
  const newOrgs = orgs.map((obj) => {
    if (obj.id === selectedOrgId) {
      return { ...obj, users: obj.users.filter((o) => o.id !== userId) };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgs));
};

export const exitOrg = (orgId) => async (dispatch, getState) => {
  const body = { org_id: orgId };
  const { status, data } = await axios.post("/v1/exitOrg", body);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(getState());
  const newOrgs = orgs.filter((obj) => obj.id !== orgId);

  const otherOrg = newOrgs[0];
  const otherOrgYear = otherOrg?.fc_years?.[0]
  dispatch(changeFcYear(otherOrg?.id, otherOrgYear.id));
  dispatch(set("orgs", newOrgs));
};

export const updateUserPermissions = (userId, details) => async (dispatch, getState) => {
  const state = getState();
  const selectedOrgId = getSelectedOrgId(state);
  const body = { user_id: userId, org_id: selectedOrgId, ...details };
  const { status, data } = await axios.post("/v1/updateUserPermissions", body);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(getState());
  const newOrgs = orgs.map((obj) => {
    if (obj.id === selectedOrgId) {
      return {
        ...obj,
        users: obj.users.map((user) => {
          if (user.id === userId) {
            return { ...user, ...details };
          }
          return user;
        }),
      };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgs));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const state = getState();
  const profile = getProfile(state);
  const prevSettings = profile.settings || {};
  const newSettings = { ...prevSettings, ...settings };
  const { status, data } = await axios.post("/v1/updateUserSettings", newSettings);
  if (status !== 200) {
    throw data;
  }
  const newProfile = { ...profile, settings: newSettings };
  dispatch(set("profile", newProfile));
};

export const updateProfileName = (fname, lname) => async (dispatch, getState) => {
  const { status, data } = await axios.post("/v1/updateProfileName", { fname, lname });
  if (status !== 200) {
    throw data;
  }
  const profile = getProfile(getState());
  const newProfile = { ...profile, fname, lname };
  dispatch(set("profile", newProfile));
  updateProfileDetailsInLocalStoargeSessions(profile.email, newProfile);
};

export const getUpdateEmailOtp = async (email) => {
  const { status, data } = await axios.post("/v1/getUpdateEmailOtp", { email });
  if (status !== 200) {
    throw data;
  }
  return data?.reqId;
};


export const updateEmail = (reqId, oldEmailOtp, newEmailOtp, newEmail) => async (dispatch, getState) => {
  const { status, data } = await axios.post("/v1/updateEmail", { reqId, oldEmailOtp, newEmailOtp });
  if (status !== 200) {
    throw data;
  }
  const profile = getProfile(getState());
  const newProfile = { ...profile, email: newEmail };
  dispatch(set("profile", newProfile));
  updateProfileDetailsInLocalStoargeSessions(profile.email, newProfile);
};


const updateProfileDetailsInLocalStoargeSessions = (email, details) => {
  const sessions = getSessions();
  const updatedSessions = sessions.map(obj => {
    if (obj.email === email) {
      return {
        ...obj,
        fname: details.fname || obj.fname,
        lname: details.lname || obj.lname,
        email: details.email || obj.email,
      }
    } else {
      return obj;
    }
  });
  setSessions(updatedSessions);
}

export const loadProfile = () => async (dispatch) => {
  const { status, data } = await axios.get("/v1/getProfile", {});
  if (status !== 200) {
    throw data;
  }

  dispatch(set("profile", data));
  dispatch(set("profileLoaded", true));
  updateProfileDetailsInLocalStoargeSessions(data.email, data)
};

export const loadUserOrganizations = () => async (dispatch) => {
  const { status, data } = await axios.get("/v1/getUserOrganizations", {});
  if (status !== 200) {
    throw data;
  }

  const orgs = data;

  dispatch(set("orgs", orgs));

  if (orgs.length === 0) {
    return;
  }

  let selectedOrgId = localStorage.getItem("selectedOrgId");
  let selectedFcYearId = localStorage.getItem("selectedFcYearId");

  const org = orgs.find((obj) => obj.id === selectedOrgId);
  const fcYears = org ? org.fc_years : [];
  const fcYear = fcYears.find((obj) => obj.id === selectedFcYearId);

  if (!selectedOrgId || !selectedFcYearId || !fcYear) {
    selectedOrgId = orgs[0].id;
    selectedFcYearId = orgs[0].fc_years.slice().sort((a, b) => (a.date2 > b.date2 ? -1 : 1))[0].id;
  }

  dispatch(changeFcYear(selectedOrgId, selectedFcYearId));
};


export const loadUserOrgGroups = () => async (dispatch) => {
  const { status, data } = await axios.get("/v1/getUserOrgGroups", {});
  if (status !== 200) {
    throw data;
  }

  const orgGroups = data;
  dispatch(set("orgGroups", orgGroups));
};

export const loadOrganizationDetails = () => async (dispatch, getState) => {
  const state = getState();
  const { status, data } = await axios.get("/v1/getOrganizationDetails", {
    params: { org_id: getSelectedOrgId(state) },
  });
  if (status !== 200) {
    throw data;
  }

  dispatch(set("orgDetails", data));
};

export const editOrgProfile = (orgInfo) => async (dispatch, getState) => {
  const state = getState();
  const orgDetails = getOrgDetails(state);
  const newOrgDetails = { ...orgDetails, ...orgInfo };
  const { status, data } = await axios.post("/v1/updateOrganizationDetails", newOrgDetails);
  if (status !== 200) {
    throw data;
  }

  dispatch(set("orgDetails", newOrgDetails));
};

export const editOrgSettings = (settings) => async (dispatch, getState) => {
  const state = getState();
  const orgDetails = getOrgDetails(state);
  const newOrgDetails = { ...orgDetails, settings };
  const { status, data } = await axios.post("/v1/updateOrganizationDetails", newOrgDetails);
  if (status !== 200) {
    throw data;
  }

  dispatch(set("orgDetails", newOrgDetails));
};

// const checkIsUpdateAvailabe = async () => {
//   const pendingSW = await getPendingServiceWorker();
//   const isUpdateAvailable = !!pendingSW;
//   return isUpdateAvailable;
// };

// export const loadIsUpdateAvailable = () => async (dispatch) => {
//   const isUpdateAvailable = await checkIsUpdateAvailabe();
//   dispatch(setIsUpdateAvailabe(isUpdateAvailable));
// };

export const changeFcYear = (orgId, fcYearId) => (dispatch, getState) => {
  const { selectedOrgId, selectedFcYearId } = getState();
  if (selectedOrgId !== orgId) {
    dispatch(set("masters", {}));
    dispatch(set("orgDetails", {}));
    dispatch(set("settings", []));
    dispatch(set("printSettings", []));
  }
  if (selectedFcYearId !== fcYearId) {
    dispatch(set("transactions", {}));
    dispatch(set("tabs", []));
    dispatch(set("activeTabId", ""));
    dispatch(set("gstr2bReport", null));
    dispatch(set("gstr2bReportFormValues", null));
  }
  dispatch(set("selectedFcYearId", fcYearId));
  dispatch(set("selectedOrgId", orgId));
  localStorage.setItem("selectedOrgId", orgId);
  localStorage.setItem("selectedFcYearId", fcYearId);
};

export const getOrganizations = (state) => get(state, "orgs", []);
export const getOrgGroups = (state) => get(state, "orgGroups", []);
export const getProfile = (state) => get(state, "profile", {});
export const getSelectedOrgId = (state) => get(state, "selectedOrgId");
export const getSelectedFcYearId = (state) => get(state, "selectedFcYearId");
export const getOrgProfile = (state) => {
  const orgDetails = { ...get(state, "orgDetails", {}) };
  delete orgDetails.settings;
  return orgDetails;
};
export const getSelectedFcYearDetails = (state) => {
  const orgs = getOrganizations(state);
  const orgId = getSelectedOrgId(state);
  const fcYearId = getSelectedFcYearId(state);
  const fcYears = orgs.find((obj) => obj.id === orgId)?.fc_years ?? [];
  const fcYear = fcYears.find((obj) => obj.id === fcYearId);
  return fcYear ?? {};
};
export const getOrgSettings = (state) => get(state, "orgDetails.settings", {});
export const getOrgDetails = (state) => get(state, "orgDetails", {});
export const getAppCurrentVersion = () => packageInfo.version;
export const getIsUpdateAvailable = (state) => get(state, "updateAvailable", false);
export const getIsUpdateAcknowledged = () => localStorage.getItem("updateAcknowledged") === "true";
export const resetUpdateAcknowledged = () => localStorage.setItem("updateAcknowledged", "");
export const acknowledgeUpdate = () => localStorage.setItem("updateAcknowledged", "true");
export const setIsUpdateAvailabe = (updateAvailable) => set("updateAvailable", updateAvailable);
export const getCompanyUsers = (state) => {
  const companies = getOrganizations(state);
  const selectedCompanyId = getSelectedOrgId(state);
  const selectedCompany = companies.find((obj) => obj.id === selectedCompanyId);
  return selectedCompany?.users ?? [];
};

export const getUserOrgDetails = (state) => {
  const userId = get(state, "profile.id");
  const orgs = getOrganizations(state);
  const selectedOrgId = getSelectedOrgId(state);
  const selectedOrgDetails = orgs.find((obj) => obj.id === selectedOrgId);
  const orgUsers = selectedOrgDetails?.users ?? [];
  return orgUsers.find((obj) => obj.id === userId) || {}
}

export const updateApp = async () => {
  try {
    const pendingSW = await getPendingServiceWorker();
    if (pendingSW) {
      pendingSW.postMessage({ type: "SKIP_WAITING" });
      // Reset `updateAcknowledged`
      localStorage.setItem("updateAcknowledged", "");
      localStorage.setItem("promptReleaseNotes", "true");
      setTimeout(() => window.location.reload(true), 100);
    }
  } catch (error) {
    notifyError("Error updating app", "", error);
  }
};

const getPendingServiceWorker = async () => {
  try {
    const registration = await navigator.serviceWorker.getRegistration(
      `${process.env.PUBLIC_URL}/service-worker.js`
    );
    return Promise.resolve(registration && registration.waiting ? registration.waiting : null);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createNewFinancialYear = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/createNewFinancialYear", requestBody);
  if (status !== 200) {
    throw data;
  }

  await dispatch(loadUserOrganizations())
};

export const closeYear = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/closeYear", requestBody);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(state);
  const newOrgsData = orgs.map((obj) => {
    if (obj.id === orgId) {
      const fcYears = obj.fc_years ?? [];
      const newFcYears = fcYears.map((obj) => {
        if (obj.id === fyId) {
          return { ...obj, closed: true };
        }
        return obj;
      });
      return { ...obj, fc_years: newFcYears };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgsData));
};

export const reopenYear = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/reopenYear", requestBody);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(state);
  const newOrgsData = orgs.map((obj) => {
    if (obj.id === orgId) {
      const fcYears = obj.fc_years ?? [];
      const newFcYears = fcYears.map((obj) => {
        if (obj.id === fyId) {
          return { ...obj, closed: false };
        }
        return obj;
      });
      return { ...obj, fc_years: newFcYears };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgsData));
};

export const carryForwardOutstandingTransactions = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/carryForwardOutstandingTransactions", requestBody);
  if (status !== 200) {
    throw data;
  }
};

export const carryForwardOutstandingStock = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/carryForwardOutstandingStock", requestBody);
  if (status !== 200) {
    throw data;
  }
};


export const carryForwardAccountBalances = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/carryForwardAccountBalances", requestBody);
  if (status !== 200) {
    throw data;
  }
};

export const carryForwardBankBalances = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/carryForwardBankBalances", requestBody);
  if (status !== 200) {
    throw data;
  }
};

export const carryForwardStockBalances = () => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const fyId = getSelectedFcYearId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/carryForwardStockBalances", requestBody);
  if (status !== 200) {
    throw data;
  }
};


export const updateFcYearPeriod = (fyId, date1, date2) => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
    date1,
    date2
  };
  const { status, data } = await axios.post("/v1/company/updateFcYearPeriod", requestBody);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(state);
  const newOrgsData = orgs.map((obj) => {
    if (obj.id === orgId) {
      const fcYears = obj.fc_years ?? [];
      const newFcYears = fcYears.map((obj) => {
        if (obj.id === fyId) {
          return { ...obj, date1, date2 };
        }
        return obj;
      });
      return { ...obj, fc_years: newFcYears };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgsData));
};


export const deleteFcYear = (fyId) => async (dispatch, getState) => {
  const state = getState();
  const orgId = getSelectedOrgId(state);
  const requestBody = {
    org_id: orgId,
    fy_id: fyId,
  };
  const { status, data } = await axios.post("/v1/company/deleteFcYear", requestBody);
  if (status !== 200) {
    throw data;
  }

  const orgs = getOrganizations(state);
  const selectedFcYearId = getSelectedFcYearId(state);
  if (selectedFcYearId === fyId) {
    const otherOrgYear = orgs.find(obj => obj.id === orgId).fc_years.filter(obj => obj.id !== fyId)
      .sort((a, b) => a.date1 < b.date1 ? 1 : -1)[0];
    if (!otherOrgYear) {
      notifyError("This year cannot be deleted since this is the only year in the company");
      return;
    }
    dispatch(changeFcYear(orgId, otherOrgYear.id));
  }
  const newOrgsData = orgs.map((obj) => {
    if (obj.id === orgId) {
      const fcYears = obj.fc_years ?? [];
      const newFcYears = fcYears.filter((obj) => obj.id !== fyId);
      return { ...obj, fc_years: newFcYears };
    }
    return obj;
  });
  dispatch(set("orgs", newOrgsData));
};
